/* eslint-disable jsx-a11y/label-has-associated-control */
// ---------------------------------------------------------

import { useEffect, useState, useCallback } from "react"
import Link from "next/link"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ReactSVG } from "react-svg"
import { useRouter } from "next/router"

// ---------------------------------------------------------

import { useMediaQuery } from "react-responsive"

// ---------------------------------------------------------

import Navigation from "./navigation"
import Search from "./search"

// ---------------------------------------------------------

import {
  demo_button,
  header,
  contact_sales_button,
  login_link,
  logo,
  menu_button_container,
  dots,
  dot,
  x,
  circle,
  clicked,
  menu_button,
  menu_container,
  menu_on_large_screens,
  menu_on_small_screens,
  navigation_container,
  navigation_is_showing,
  search_is_showing
} from "./styles.module.scss"

// ---------------------------------------------------------

const Header = ({
  contactSalesProps,
  login,
  requestDemoProps,
  menu,
  logoOnly,
  hideCtas,
  demoOnly
}) => {
  const router = useRouter()

  const [navigationIsShowing, setNavigationIsShowing] = useState(false)
  const [searchIsActive, setSearchIsActive] = useState(false)

  // -------------------------------------------------------

  const isDesktop = useMediaQuery({ query: "(min-width: 1140px)" })

  // -------------------------------------------------------

  const smallScreenMenuClick = () => {
    if (!isDesktop) {
      lockScroll(!navigationIsShowing)
      setNavigationIsShowing(!navigationIsShowing)
    }
  }

  const resetMenu = useCallback(() => {
    lockScroll(false)
    setNavigationIsShowing(false)
  }, [])

  const lockScroll = (isOpen) => {
    const mainElement = document.querySelector("main")
    const bodyElement = document.querySelector("body")

    if (isOpen) {
      bodyElement.style.overflow = "hidden"
      mainElement.classList.add("scroll-locked")
    } else {
      bodyElement.style.overflow = "auto"
      mainElement.classList.remove("scroll-locked")
    }
  }

  useEffect(() => {
    router.events.on("routeChangeComplete", resetMenu)
    return () => {
      router.events.off("routeChangeComplete", resetMenu)
    }
  }, [router, resetMenu])

  // -------------------------------------------------------

  const handleSearchIsActive = (value) => {
    setSearchIsActive(value)
  }
  // -------------------------------------------------------

  const shouldBeDisabled = () => {
    if (isDesktop) {
      return searchIsActive
    } else {
      return false
    }
  }

  // -------------------------------------------------------

  const [showNavItems, setShowNavItems] = useState(false)

  useEffect(() => {
    if (router.isReady) {
      setShowNavItems(true)
    }
  }, [router.isReady, showNavItems])

  // ---------------------------------------------------------

  const classes = classNames(header, {
    [navigation_is_showing]: navigationIsShowing,
    [search_is_showing]: searchIsActive
  })

  // -------------------------------------------------------

  const amperityLogo = (
    <Link
      href="/"
      aria-label="Amperity Home"
      className={logo}
      onClick={() => handleClick("nav", router.asPath, "", "/")}
    >
      <ReactSVG src={`/svg/amperity-logo.svg`} />
    </Link>
  )

  const smallMenuButton = (
    <div aria-label="menu" className={menu_button}>
      <span>Menu</span>
      <div className={menu_button_container}>
        <button
          data-testid="mobile-menu"
          aria-label="Mobile menu open/close button"
          className={navigationIsShowing ? clicked : null}
          onClick={smallScreenMenuClick}
        >
          <div className={dots}>
            <div
              className={classNames({
                [x]: navigationIsShowing,
                [circle]: !navigationIsShowing
              })}
            >
              <div className={dot}></div>
            </div>
          </div>
        </button>
      </div>
    </div>
  )

  const handleClick = (category = "nav", currentUrl, label, destinationUrl) => {
    if (window._paq) {
      window._paq.push([
        "trackEvent",
        category,
        `Current Page: ${currentUrl}`,
        `${label} - ${destinationUrl}`
      ])
    }

    if (window.drift) window.drift.page()

    // eslint-disable-next-line
    dataLayer.push({
      event: category,
      current_url: currentUrl,
      destination_url: destinationUrl
    })
  }

  const demoButton = (
    <Link href={requestDemoProps?.url} onClick={resetMenu}>
      <button
        onClick={() =>
          handleClick("nav", router.asPath, requestDemoProps?.label, requestDemoProps?.url)
        }
        className={demo_button}
      >
        {requestDemoProps?.label || "Demo"}
      </button>
    </Link>
  )

  const contactSalesButton = (
    <Link href={contactSalesProps?.url} onClick={resetMenu}>
      <button
        onClick={() =>
          handleClick("nav", router.asPath, contactSalesProps?.label, contactSalesProps?.url)
        }
        className={contact_sales_button}
      >
        {contactSalesProps?.label}
      </button>
    </Link>
  )

  const fullNavigationRightOptions = (
    <div className={menu_on_large_screens}>
      <Search handleSearchIsActive={handleSearchIsActive} />

      <a
        className={login_link}
        href={!shouldBeDisabled() && login.buttonUrl}
        target="_blank"
        rel="noreferrer noopener"
        disabled={shouldBeDisabled()}
      >
        {login.buttonLabel}
      </a>
      {demoButton}
      {contactSalesButton}
    </div>
  )

  const demoOnlyRightOption = <div className={menu_on_large_screens}>{demoButton}</div>

  const navigation = (
    <Navigation
      className={navigation_container}
      links={menu}
      navigationIsShowing={navigationIsShowing}
      searchIsActive={searchIsActive}
      buttons={[demoButton, contactSalesButton]}
    />
  )

  // -------------------------------------------------------
  console
  return (
    <header className={classes}>
      {showNavItems && (
        <div className={menu_container}>
          {!isDesktop && (
            <div className={menu_on_small_screens}>
              {amperityLogo}
              {smallMenuButton}
            </div>
          )}

          {!isDesktop && navigation}

          {isDesktop && (
            <>
              {amperityLogo}
              {logoOnly ? null : navigation}
              {logoOnly || hideCtas ? null : fullNavigationRightOptions}
              {demoOnly ? demoOnlyRightOption : null}
            </>
          )}
        </div>
      )}
    </header>
  )
}

// ---------------------------------------------------------

Header.propTypes = {
  /**
   * An object used to build the button
   */
  contactSalesProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }),

  /**
   * An object used to build a right aligned link
   */
  login: PropTypes.shape({
    buttonLabel: PropTypes.string.isRequired,
    buttonUrl: PropTypes.string.isRequired
  }),

  /**
   * An object used to build a right aligned link
   */
  requestDemoProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }),

  /**
   * An array of main navigation links.
   * This prop is passed to the Navigation component
   */
  menu: PropTypes.array.isRequired,

  /**
   * Hides all the items except for the Amperity logo in the nav bar
   */
  logoOnly: PropTypes.bool,

  /**
   * Hides the CTAs in the nav bar
   */
  hideCtas: PropTypes.bool,

  /**
   * Hides everything except the demo button
   */
  demoOnly: PropTypes.bool
}

// ---------------------------------------------------------

export default Header
