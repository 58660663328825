import { parameterize } from "@lib/utils"

const transformed = (input) => {
  return {
    ...input,
    ...input.image
  }
}

export default transformed
