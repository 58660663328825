// this avoids the next/router dependency, which will break the storybook build
import { useNinetailed as useRealNinetailed } from "@ninetailed/experience.js-react"

const mockNinetailed = () => ({
  identify: () => {},
  on: () => {},
  track: () => {}
})

const ninetailedHookFactory = () => {
  if (process.env.STORYBOOK_ENV === "storybook") {
    return mockNinetailed
  } else {
    return useRealNinetailed
  }
}

export const useNinetailed = () => {
  const ninetailedHook = ninetailedHookFactory()
  return ninetailedHook()
}
