import { useState, useEffect } from "react"
import { useDataContext } from "../../DataProvider"

const emailValidationForms = ["1681", "1162", "1699"] // A list of form ids where certain email domains are blocked

/**
 * Method to set the initial Forms2 Script
 * @param { string } baseUrl
 * @param { func } setScriptLoaded
 * @returns undefined
 */
function appendForms2Script(baseUrl, setScriptLoaded) {
  if (window.MktoForms2) return setScriptLoaded(true)

  const script = document.createElement("script")
  script.src = `${baseUrl}/js/forms2/js/forms2.min.js`
  script.onload = () => (window.MktoForms2 ? setScriptLoaded(true) : null)
  document.body.appendChild(script)
}

/**
 * @param { string } email
 * @returns Boolean
 */
function isValidEmail(email) {
  let invalidDomains = ["@gmail."] // A list of email domains that prevent user from submitting form

  for (var i = 0; i < invalidDomains.length; i++) {
    if (email.includes(invalidDomains[i])) {
      return false
    }
  }
  return true
}

function useMarketo({ baseUrl, munchkinId, formId, contentKey, callback }) {
  const { setEmail } = useDataContext()
  const [scriptLoaded, setScriptLoaded] = useState(false)
  const [formsLoaded, addFormLoaded] = useState([])

  useEffect(() => {
    // check if component has been loaded / mounted - should only run once
    if (scriptLoaded && !formsLoaded.includes(formId)) {
      if (formId) {
        // https://assets.codepen.io/250687/teknkl-formsplus-tag-0.2.3.js
        window.FormsPlus = window.FormsPlus || { allDescriptors: {}, allMessages: {}, detours: {} }
        window.FormsPlus.tagWrappers = function tagWrappers() {
          var ANCESTORS_STOR = ".mktoFormRow, .mktoFormCol",
            INPUTS_STOR = "INPUT,SELECT,TEXTAREA,BUTTON,[data-name],.mktoPlaceholder,LEGEND",
            attrTag = "data-wrapper-for",
            attrDone = "data-initial-wrapper-tagging-complete",
            placeholderPrefix = /^mktoPlaceholder/,
            arrayify = getSelection.call.bind([].slice)
          function tagMktoWrappers(formEl) {
            arrayify(formEl.querySelectorAll(ANCESTORS_STOR)).forEach(function (ancestor) {
              ancestor.setAttribute(attrTag, "")
              arrayify(ancestor.querySelectorAll(INPUTS_STOR)).forEach(function (input) {
                var currentTag = ancestor.getAttribute(attrTag)
                ancestor.setAttribute(
                  attrTag,
                  [
                    currentTag ? currentTag : "",
                    input.id,
                    input.name != input.id ? input.name : "",
                    input.getAttribute("data-name"),
                    input.nodeName == "LEGEND" ? input.textContent : "",
                    arrayify(input.classList)
                      .filter(function (cls) {
                        return placeholderPrefix.test(cls)
                      })
                      .map(function (cls) {
                        return cls.replace(placeholderPrefix, "")
                      })
                      .join(" ")
                  ]
                    .join(" ")
                    .trim()
                )
              })
            })
          }
          window.MktoForms2?.whenRendered(function (form) {
            var formEl = form.getFormElem()[0]
            tagMktoWrappers(formEl)
            formEl.setAttribute(attrDone, "true")
          })
        }
        window.FormsPlus.tagWrappers()
      }

      window.MktoForms2?.whenReady(function (form) {
        if (emailValidationForms.includes(formId)) {
          form.onValidate(function () {
            let email = form.vals()?.Email
            if (!isValidEmail(email)) {
              form.submitable(false)
              var emailElem = form.getFormElem().find("#Email")
              form.showErrorMessage("Must be business email.", emailElem)
            } else {
              setEmail(email)
              form.submitable(true)
            }
          })
        }
      })

      window.MktoForms2?.loadForm(baseUrl, munchkinId, formId, function (form) {
        form.vals({
          // Set value for hidden field Form Content Key
          formContentKey: contentKey
        })
        if (callback) {
          form.onSuccess(callback)
        }
      })
      addFormLoaded([...formsLoaded, formId])
    }
    appendForms2Script(baseUrl, setScriptLoaded)
  }, [scriptLoaded, formsLoaded, baseUrl, munchkinId, formId, callback, setEmail, contentKey])
}

export default useMarketo
