import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Link from "next/link"
import { ReactSVG } from "react-svg"

// ---------------------------------------------------------

import NewsletterSignUp from "../../components/forms/newsletter-signup"

// ---------------------------------------------------------

import {
  column,
  copyright,
  footer_container,
  footer_content,
  footer_logo,
  footer_form,
  instagram,
  facebook,
  link_lists,
  linkedin,
  preview_margin_bottom,
  social_media,
  subheader,
  twitter,
  youtube
} from "./styles.module.scss"

// ---------------------------------------------------------

const Footer = (props) => {
  let { formHeading, lists, preview } = props

  return (
    <footer className={classNames(footer_container, { [preview_margin_bottom]: preview })}>
      <div className={footer_content}>
        <div className={footer_logo}>
          <ReactSVG src={`/svg/amperity-logo.svg`} />
        </div>
        <section>
          <NewsletterSignUp
            className={footer_form}
            formId="1318"
            heading={formHeading}
            theme="gray-background"
          />

          <div className={link_lists}>
            {lists.map((list, index) => {
              return (
                <div className={column} key={index}>
                  <ul>
                    {list.listItems.map((item) => {
                      return item.subheader ? (
                        <li key={item.label}>
                          <p className={subheader}>{item.label}</p>
                        </li>
                      ) : (
                        <li key={item.label}>
                          <Link href={item.url}>{item.label}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            })}
          </div>
          <section className={social_media}>
            <a
              href="https://www.linkedin.com/company/amperity/"
              className={linkedin}
              target="_blank"
              rel="noreferrer noopener"
              aria-label="LinkedIn"
            >
              <ReactSVG src={`/icons/logo-linkedin.svg`} />
            </a>
            <a
              href="https://www.youtube.com/amperity"
              className={youtube}
              target="_blank"
              rel="noreferrer noopener"
              aria-label="YouTube"
            >
              <ReactSVG src={`/icons/logo-youtube.svg`} />
            </a>
            <a
              href="https://www.instagram.com/amperity/"
              className={instagram}
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Instagram"
            >
              <ReactSVG src={`/icons/logo-instagram.svg`} />
            </a>
            <a
              href="https://www.facebook.com/goamperity"
              className={facebook}
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Instagram"
            >
              <ReactSVG src={`/icons/logo-facebook.svg`} />
            </a>
            <a
              href="https://twitter.com/amperity"
              className={twitter}
              target="_blank"
              rel="noreferrer noopener"
              aria-label="Twitter"
            >
              <ReactSVG src={`/icons/logo-x-twitter.svg`} />
            </a>
          </section>
          <section className={copyright}>
            <p>
              &copy; Amperity, Inc. {new Date().getFullYear()} |{" "}
              <Link href="/legal/site-terms">Site Terms</Link>
            </p>
          </section>
        </section>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  /**
   * Specifies the heading to the NewsletterSignUp component
   */
  formHeading: PropTypes.string.isRequired,

  /**
   * An array of list objects. Each object might contain a header, and a nested list. If subheader is set to true, the component styles it accordingly and does not link it anywhere.
   */
  lists: PropTypes.arrayOf(
    PropTypes.shape({
      header: PropTypes.string,
      listItems: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          url: PropTypes.string,
          subheader: PropTypes.bool
        })
      )
    })
  ),

  /**
   * Image source for the logo
   */
  logo: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string
  }),

  preview: PropTypes.bool
}

Footer.defaultProps = {}

export default Footer
