export default {
  props: {
    src: "https://images.ctfassets.net/5vqt5w81x2im/7y56yU6bhj3swducCehFrA/c24ae4537ce6c2611415270e8bc1f763/Integrations-Hero-PingPong.png",
    alt: "Graphic showing Amperity integrations: AWS, Microsoft, Oracle, Adobe, Facebook, and more.",
    width: 4320,
    height: 2800,
    link: "",
    style: {
      objectFit: "contain"
    }
  },
  withLink: {
    link: "https://amperity.com/"
  },
  withCaption: {
    caption: "Adding captions can help to clarify callouts and assist with flow of communication"
  }
}
