import { parameterize } from "@lib/utils"

const transformed = (input) => {
  return {
    ...input,
    themeColor: parameterize(input.themeColor),
    theme: input.hover === true ? "hover" : input.theme ? parameterize(input.theme) : "default",
    image: {
      src: input.image?.src || input.image?.url,
      alt: input.image?.alt || input.image?.description,
      width: input.image?.width,
      height: input.image?.height
    }
  }
}

export default transformed
