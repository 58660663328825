import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Link from "next/link"

// ---------------------------------------------------------

import MarketoForm from "../../marketoForm"

// ---------------------------------------------------------

import {
  newsletter_signup_form,
  theme_alert_bar,
  theme_gray_background,
  theme_white_background,
  newsletter_heading,
  form_input,
  privacy_notice
} from "./styles.module.scss"

const themeOptions = {
  "gray-background": theme_gray_background,
  "white-background": theme_white_background,
  "alert-bar": theme_alert_bar
}

// ---------------------------------------------------------

const NewsletterSignUp = (props) => {
  let { className, heading, formId, redirectUrl, theme } = props

  // -------------------------------------------------------

  const classes = classNames(newsletter_signup_form, {
    [className]: className,
    [themeOptions[theme]]: themeOptions[theme]
  })

  // -------------------------------------------------------

  return (
    <div className={classes}>
      {heading && <h3 className={newsletter_heading}>{heading}</h3>}

      <div className={classNames("form-container", form_input)}>
        <MarketoForm formId={formId} redirectUrl={redirectUrl} contentKey="newsletter-signup" />
      </div>
      <p className={privacy_notice}>
        By submitting this form, I acknowledge that my personal data will be treated in accordance
        with Amperity's <Link href="/legal/privacy-notice">Privacy Notice</Link>.
      </p>
    </div>
  )
}

NewsletterSignUp.propTypes = {
  /**
   * Specifies an optional heading
   */
  heading: PropTypes.string,

  /**
   * The Marketo form ID override.
   */
  formId: PropTypes.string,

  /**
   * Override URL for the newsletter confirmation page.
   */
  redirectUrl: PropTypes.string,

  /**
   * Specifies the theme
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions))
}

NewsletterSignUp.defaultProps = {
  theme: "gray-background"
}

export default NewsletterSignUp
