/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from "react"
import PropTypes from "prop-types"
import { ReactSVG } from "react-svg"
import dynamic from "next/dynamic"
import Link from "next/link"
import { useRouter } from "next/router"
import classNames from "classnames"
import { parseISO, compareAsc } from "date-fns"
import { getLabelIcon, getCTAFromLabel, getDateTime } from "@lib/card"
import { getCurrentDate, getUrlAndType, getFileExtension } from "@lib/utils"

// ---------------------------------------------------------

import Button from "@components/button"
import Image from "@components/image"
import SVG from "@components/image/svgs"

// ---------------------------------------------------------

const Modal = dynamic(() => import("@components/modal"), { ssr: false })
const TagList = dynamic(() => import("@components/tag-list"), { ssr: false })

// ---------------------------------------------------------

import {
  arrow_dark,
  card_svg,
  text_container,
  label_container,
  label_icon,
  large_image,
  pipe_icon,
  button_container,
  button_container_border,
  button_container_single,
  grid_card_title,
  card_image_container,
  theme_default,
  theme_hover,
  theme_hover_dark,
  theme_carousel,
  theme_color_split,
  theme_prime_red,
  theme_prime_green,
  theme_limoncello,
  theme_off_black,
  theme_white,
  open_button
} from "./styles.module.scss"

const themeOptions = {
  default: theme_default,
  hover: theme_hover,
  "hover-dark": theme_hover_dark,
  carousel: theme_carousel,
  "color-split": theme_color_split,
  "prime-red": theme_prime_red,
  "prime-green": theme_prime_green,
  limoncello: theme_limoncello,
  "off-black-whole-card": theme_off_black,
  white: theme_white
}

// ---------------------------------------------------------

const Card = (props) => {
  let {
    backgroundIsDark,
    buttonLabel,
    buttonUrl,
    className,
    description,
    endDate,
    image,
    label,
    startDate,
    timeZoneOverride,
    theme,
    title,
    location,
    tagCollection,
    modalContent,
    themeColor,
    route
  } = props

  const router = useRouter()

  const handleClick = (category = "card_click", title, currentUrl, label, destinationUrl) => {
    window._paq
      ? window._paq.push([
          "trackEvent",
          category,
          `Current Page: ${currentUrl}`,
          `${label || category} - ${destinationUrl}`
        ])
      : null

    if (window.drift) window.drift.page()

    // eslint-disable-next-line
    dataLayer.push({
      event: category,
      current_url: currentUrl,
      destination_url: destinationUrl
    })
  }

  // ---------------------------------------------------------

  let newButtonLabel = buttonLabel || getCTAFromLabel(label)

  const isHoverCard = theme === "hover" || theme === "hover-dark" || theme === "carousel"
  const isGridCard = theme === "hover-grid"
  const isColorSplitCard = theme === "color-split"

  const { isExternal, url: convertedUrl } = getUrlAndType(buttonUrl)

  const webinarHasEnded =
    label === "Webinar" && compareAsc(getCurrentDate(), parseISO(startDate)) >= 0

  // ---------------------------------------------------------

  const [isOpen, setIsOpen] = useState(false)

  // ---------------------------------------------------------

  const button =
    newButtonLabel &&
    buttonUrl && // If `theme` is hover and `buttonUrl` is provided, use fake button to avoid nesting <a> tags
    (isHoverCard ? (
      <div className={button_container}>
        <div className={arrow_dark}>{webinarHasEnded ? "Watch now" : newButtonLabel}</div>
      </div>
    ) : (
      <Button theme={backgroundIsDark ? "arrow-light" : "arrow-dark"} url={convertedUrl}>
        {newButtonLabel}
      </Button>
    ))

  const getFormattedTitle = () => {
    return isGridCard ? (
      <Link href={convertedUrl}>
        <h4 className={grid_card_title}>{title}</h4>
      </Link>
    ) : theme === "hover" || theme === "hover-dark" ? (
      <h4>{title}</h4>
    ) : (
      <h3>{title}</h3>
    )
  }
  const isLargeImage = image.width >= 500 || image.height >= 324

  const isSVG = image && image.src && getFileExtension(image.src) === "svg"

  const imageContainer =
    image.src && isSVG ? (
      <div
        className={card_image_container}
        style={{
          maxWidth: isHoverCard ? null : image.width
        }}
      >
        <SVG className={card_svg} title={image.alt} src={image.src} />
      </div>
    ) : image.src ? (
      <div style={isHoverCard ? null : { maxWidth: image.width }}>
        <Image
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          src={image.src}
          width={image.width}
          height={image.height}
          alt={image.alt}
          style={{ objectFit: "contain" }}
          className={classNames({
            [large_image]: isLargeImage
          })}
        />
      </div>
    ) : null

  const transformedLabel = label === "Research and Reports" ? "Research & Reports" : label

  const textContainer = (
    <div className={text_container}>
      {label && (isGridCard || isHoverCard) && (
        <div className={label_container}>
          <SVG className={label_icon} src={`/icons/${getLabelIcon(label)}.svg`} />
          <p>{webinarHasEnded ? `On-demand Webinar` : transformedLabel} </p>
          {!webinarHasEnded && startDate && (
            <>
              <SVG className={pipe_icon} src={`/icons/pipe-icon.svg`} />
              <p>{timeZoneOverride ? timeZoneOverride : getDateTime(startDate, endDate)}</p>
            </>
          )}
        </div>
      )}
      {getFormattedTitle()}
      {location && <p>{location}</p>}
      {description && <p>{description}</p>}

      {tagCollection && <TagList tags={tagCollection.slice(0, 3)} path={route} />}

      {button}

      {isColorSplitCard && modalContent && (
        <>
          {buttonLabel ? (
            <div className={button_container_border}>
              <p>
                <span>Case Study: </span>
                <span>{buttonLabel}</span>
              </p>
              <button>
                <ReactSVG className={open_button} src={`/icons/x-mark.svg`} alt="Open modal" />
              </button>
            </div>
          ) : (
            <button className={button_container_single}>
              <ReactSVG className={open_button} src={`/icons/x-mark.svg`} alt="Open modal" />
            </button>
          )}
        </>
      )}
    </div>
  )

  return isColorSplitCard ? (
    <>
      <div
        role="button"
        onClick={(e) => {
          e.stopPropagation()
          modalContent ? setIsOpen(true) : null
        }}
        onKeyUp={(event) => {
          if (event.key === "Enter" || event.key === " ") {
            modalContent ? setIsOpen(true) : null
          }
        }}
        tabIndex={0}
        className={classNames(
          theme_color_split,
          themeOptions[themeColor],
          modalContent ? theme_hover : null
        )}
      >
        {image && imageContainer}
        {textContainer}
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        content={modalContent}
        theme={themeColor === "off-black-whole-card" ? "off-black" : "default"}
      />
    </>
  ) : isHoverCard && convertedUrl ? (
    // eslint-disable-next-line react/jsx-no-target-blank
    <Link
      href={convertedUrl}
      data-testid="card-hover"
      target={isExternal ? "_blank" : "_self"}
      rel={isExternal ? "noreferrer" : "none"}
      onKeyDown={() => handleClick("card_click", title, router.asPath, buttonLabel, buttonUrl)}
      onClick={() => handleClick("card_click", title, router.asPath, buttonLabel, buttonUrl)}
      className={classNames(themeOptions[theme], {
        [className]: className
      })}
    >
      <>
        {image && imageContainer}
        {textContainer}
      </>
    </Link>
  ) : (
    <div
      data-testid="card"
      className={classNames(theme_default, {
        [className]: className
      })}
    >
      {image && convertedUrl ? (
        <Link
          href={convertedUrl}
          target={isExternal ? "_blank" : "_self"}
          onKeyDown={() => handleClick("card_click", title, router.asPath, buttonLabel, buttonUrl)}
          onClick={() => handleClick("card_click", title, router.asPath, buttonLabel, buttonUrl)}
        >
          {imageContainer}
        </Link>
      ) : image ? (
        imageContainer
      ) : null}
      {textContainer}
    </div>
  )
}

Card.propTypes = {
  /**
   * Card theme.
   */
  theme: PropTypes.oneOf([
    "default",
    "hover",
    "hover-dark",
    "hover-grid",
    "color-split",
    "carousel"
  ]).isRequired,

  /**
   * Label above the text block.
   */
  label: PropTypes.string,

  /**
   * Title or header of post.
   */
  title: PropTypes.string,

  /**
   * Description of post content.
   */
  description: PropTypes.string,

  /**
   * Image above card. For resource cards, should always be ratio of 1080 x 700.
   */
  image: PropTypes.shape({
    alt: PropTypes.string,
    height: PropTypes.number,
    src: PropTypes.string,
    width: PropTypes.number
  }),

  /**
   * Text rendered on the button within text block.
   */
  buttonLabel: PropTypes.string,

  /**
   * Specifies where the button within text block goes.
   */
  buttonUrl: PropTypes.string,

  /**
   * Start date of post.
   */
  startDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),

  /**
   * End date of post.
   */
  endDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),

  /**
   * An override for the date and time of the event.
   */
  timeZoneOverride: PropTypes.string,

  /**
   * Specifies if the background of the card text field is dark
   */
  backgroundIsDark: PropTypes.bool,

  /**
   * Year value for Awards page
   */
  year: PropTypes.number,

  /**
   * Specifies the location
   */
  location: PropTypes.string,

  /**
   * A list of tags associated with the entry
   */
  tagCollection: PropTypes.array,

  /**
   * Specifies the route that card will link to, if applicable
   */
  route: PropTypes.string,

  /**
   * Specifies the modal content
   */
  modalContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Specifies the theme color for the color-split card theme
   */
  themeColor: PropTypes.oneOf([
    "white",
    "prime-red",
    "prime-green",
    "limoncello",
    "off-black-whole-card"
  ])
}

Card.defaultProps = {
  backgroundIsDark: false,
  image: null,
  theme: "default"
}

export default Card
