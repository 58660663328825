export default {
  Default: {
    heading:
      "Sign up for monthly updates on brands, data, and life in the age of customer-centricity.",
    theme: "gray-background",
    formId: "1318"
  },
  themeWhiteBackground: {
    theme: "white-background",
    formId: "2454"
  },
  themeAlertBar: {
    theme: "alert-bar",
    formId: "2028"
  }
}
