import { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ReactSVG } from "react-svg"

// ---------------------------------------------------------

import {
  search_button_container,
  search_container,
  search_container_active,
  search_input
} from "./styles.module.scss"

// ---------------------------------------------------------

const Search = ({ className, handleSearchIsActive }) => {
  const [searchIsActive, setSearchIsActive] = useState(false)
  const inputRef = useRef()

  // -------------------------------------------------------

  const handleOutsideClick = (e) => {
    if (inputRef.current && e.target !== inputRef.current) {
      setSearchIsActive(false)
    }
  }

  // -------------------------------------------------------

  useEffect(() => {
    if (searchIsActive) {
      inputRef.current.focus()
    }
    handleSearchIsActive(searchIsActive)

    document.addEventListener("click", handleOutsideClick, false)
    return () => {
      document.removeEventListener("click", handleOutsideClick, false)
    }
  })

  // -------------------------------------------------------

  const inputClasses = classNames("st-default-search-input", search_input)

  const formClasses = classNames(className, search_container, {
    [search_container_active]: searchIsActive
  })

  // -------------------------------------------------------

  const getInput = () => {
    return (
      <input
        aria-label="search-input"
        data-testid="search-input"
        className={inputClasses}
        placeholder="Search Amperity"
        ref={inputRef}
        onClick={() => setSearchIsActive(true)}
        role="searchbox"
        type="text"
      />
    )
  }

  // -------------------------------------------------------

  return (
    <form className={formClasses}>
      {getInput()}
      <button className={search_button_container} aria-label="search">
        <ReactSVG src={`/icons/magnifying-glass.svg`} />
      </button>
    </form>
  )
}

Search.propTypes = {
  /**
   * Specifies a function passed down from Header to let each child know when search is active
   */
  handleSearchIsActive: PropTypes.func,

  /**
   * Class from parent component
   */
  className: PropTypes.string
}

export default Search
